import { Brand } from "features/RMARequests/store/model/RmaSearchQuery";
import { validationResults } from "./RMAInvoiceItemsValidationQuery";

export enum SearchType {
  invoice = "Invoice",
  saleOrder = "Sales Order"
}

export enum ScreenStage {
  search = "Search",
  requestForm = "RequestForm",
  reviewScreen = "ReviewScreen",
  RMASubmit = "RMASubmit",
  RMASubmitFailed = "RMASubmitFailed"
}

export interface RmaEntrySearchQuery {
  invoiceNumber?: string,
  salesOrderNumber?: string,
  type: SearchType
}

export const RMAEntryInitialValues ={
    invoiceNumber: "",
    purchaseOrderNumber: "",
    accountNumber: "",
    salesOrg: "",
    division: "",
    jobIndicator: "",
    salesOrderNumber: "",
    invoiceDate: "",
    specialMarkingsInstructions: "",
    items: [
  ]
}

export interface RMAEntryResponse {
  invoiceNumber: string;
  accountNumber: string;
  salesOrg?: string;
  division?: string;
  jobIndicator: string;
  salesOrderNumber: string;
  invoiceDate: string;
  purchaseOrderNumber: string;
  specialMarkingsInstructions: string;
  brand?: Brand;
  items: RMAItem[];
}

export interface RMAReviewFormResponse {
  customerContactExt: string;
  customerNotes: string;
  debitMemoNumber: string;
  reasonForReturn: string;
  ext: string;
  firstName: string;
  internalNotes: string;
  searchNumber: string;
  lastName: string;
  phoneNumber: string;
  purchaseOrderNumber: string;
  salesAgentExt: string;
  salesAgentFirstName: string;
  salesAgentLastName: string;
  salesAgentPhoneNumber: string;
}

export interface  RMAUpdateField {
  reason:{
    code: string;
    name: string;
  };
  isSelected:string[];
  quantity: string;
  itemNumber:string;
}

export interface RMAItem {
  invoiceNumber: string;
  materialNumber: string;
  customerMaterialNumber: string;
  materialDescription: string;
  division: string;
  quantity: string;
  unitOfMeasure: string;
  itemNumber: string;
  plant: string;
  itemCategory: string;
  salesOrg: string;
  salesOrderNumber: string;
  returnReasons?:RMAReturnsReasons[];
  reason?:{
    code: string;
    name: string;
  };
  isSelected?:boolean;
  isQuantityValid?:boolean;
  isQuantityEdited?:boolean;
  validationResults?: validationResults[],
  itemsValidtionFailed?:boolean;
}

export interface RmaRequestForm {
  purchaseOrderNumber: string;
  searchNumber: string;
  debitMemoNumber: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  salesAgentPhoneNumber: string;
  internalNotes: string;
  customerNotes: string;
  salesAgentFirstName: string;
  salesAgentLastName: string;
  ext: string;
  customerContactExt: number | null;
  salesAgentExt: number | null;
  reasonForReturn: string;
}

// export interface RmaRequestTableForm {
//   reason: string;
// }

export interface ReviewScreenSearchQuery {
  accountNumber: string;
  salesOrg: string;
  division: string;
  invoiceNumber: string;
  customerDocNumber: string;
  debitMemoNumber: string;
  customerContact: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    extension: string;
  };
  salesAgentContact: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    extension: string;
  };
  customerNotes: string;
  internalNotes: string;
  rmaEmail: string;
  returnReason: {
    code: string;
    name: string;
  };
  items: Items[];
}
export interface Items {
  materialNumber: string;
  itemNumber: string;
  invoiceNumber: string;
  invoiceLineNumber: string;
  returnReason: {
    code: string;
    name: string;
  };
  quantity: string;
  reqDate: Date;
  plant: string;
  priceDate: Date;
}

export interface ReviewScreenResponse {
  status : string,
  errors : Warnings[],
  warnings : Warnings[]
} 
export interface Warnings {
  number : string
  message : string
  }

export interface RmaInvoiceData {}


export interface RMAReturnsReasons{
  Code: string,
  Name: string,
  SalesOrg: string
}

export interface RMAReasons{
  code:string;
  value:string;
}

export interface ReturnReason{
  code: string;
  name: string;
}

