import {
  AsyncStateObjectInitialStateFactory,
  AsyncStatus,
} from "store/AsyncStateObject";

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  initialRmaEntrySearchQueryState,
  RmaEntrySearchState,
  initialreviewFormDetailsState,
  initialReasonState,
} from "./model/RmaEntrySearchState";

import {
  ReviewScreenSearchQuery,
  RmaEntrySearchQuery,
  RMAReasons,
  RMAReturnsReasons,
  RMAReviewFormResponse,
  RMAUpdateField,
  ScreenStage,
  SearchType,
} from "./model/RmaEntrySearchQuery";

import RMAEntryService from "../services/RMAEntryServices";

import { LocalStorage } from "store/localStorage";

import { ControlledTableState } from "features/common/components/Table/models";
import { mapSalesApiResponseToRMA } from "./helpers";
import {
  RMAInvoiceValidateQuery,
  RMAInvoiceValidateResponse,
} from "./model/RMAInvoiceItemsValidationQuery";

export const storeKey = "rmaEntrySearch";

export const initialState: RmaEntrySearchState = {
  query: initialRmaEntrySearchQueryState,
  searchResult: AsyncStateObjectInitialStateFactory(),
  formReviewDetails: initialreviewFormDetailsState,
  tableState: undefined,
  reasons: initialReasonState,
  reasonsList:undefined,
  rmaSearchType: SearchType.invoice,
  itemsValidtionFailed: false,
  rmaScreenStage: ScreenStage.search,
};

const cachedState: RmaEntrySearchState = LocalStorage.loadSerialized(storeKey);

export const searchRMAEntry = createAsyncThunk(
  storeKey + "/searchRmaEntry",

  async (query: RmaEntrySearchQuery, thunkApi) => {
    const RmaEntrySearchService = new RMAEntryService();

    const abortController = new AbortController();

    const abortSignal = abortController.signal;

    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    await RmaEntrySearchService.getRMARequestForm(query, abortSignal)
      .then((r) => {
        data = r;
      })
      .catch((e) => {
        return Promise.reject(e?.message || e);
      });
    return data;
  }
);

export const rmaEntrySubmission = createAsyncThunk(
  storeKey + "/searchRmaEntry",

  async (query: ReviewScreenSearchQuery, thunkApi) => {
    const RmaEntrySearchService = new RMAEntryService();
    const abortController = new AbortController();
    const abortSignal = abortController.signal;
    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    await RmaEntrySearchService.rmaReviewScreenSubmission(query, abortSignal)
      .then((r) => {
        data = r;
      })
      .catch((e) => {
        return Promise.reject(e?.message || e);
      });
    return data;
  }
);

export const RMAItemValidation = createAsyncThunk(
  storeKey + "/RmaValidate",

  async (query: RMAInvoiceValidateQuery, thunkApi) => {
    const RmaEntrySearchService = new RMAEntryService();

    const abortController = new AbortController();

    const abortSignal = abortController.signal;

    thunkApi.signal.addEventListener("abort", () => {
      abortController.abort();
    });
    let data;
    await RmaEntrySearchService.validateInvoiceItems(query, abortSignal)
      .then((r) => {
        data = r;
      })
      .catch((e) => {
        return Promise.reject(e?.message || e);
      });
    return data;
  }
);

export const featureSliceRmaEntry = createSlice({
  name: storeKey,

  initialState: cachedState || initialState,

  reducers: {
    updateInvoiceItemValidations: (
      state,
      action: PayloadAction<RMAInvoiceValidateResponse>
    ) => {
      console.log("action.payload", action.payload);
      let UpdatedItemValidation = action.payload.items[0].validationResults;
      state.searchResult?.data?.items.forEach((item: any) => {
        if (item.itemNumber === action.payload.items[0].invoiceLineNumber) {
          item.validationResults = UpdatedItemValidation;
          if (UpdatedItemValidation.length > 0) {
            item.isSelected = false;
          }
        }
      });
    },
    setTableState: (
      state,
      action: PayloadAction<ControlledTableState | undefined>
    ) => {
      state.tableState = action.payload;
    },
    setReviewDetailState: (
      state,
      action: PayloadAction<RMAReviewFormResponse>
    ) => {
      state.formReviewDetails = action.payload;
    },
    setReasonsDropdownState: (state, action: PayloadAction<RMAReasons>) => {
      state.reasons = action.payload;
    },
    
    setreasonDropDownListstate: (state, action: PayloadAction<RMAReturnsReasons[]>) => {
      state.reasonsList = action.payload;
    },

    setQuantityState: (state, action: PayloadAction<RMAUpdateField>) => {
      state.searchResult?.data?.items.forEach((item: any) => {
        if (item.itemNumber === action.payload.itemNumber) {
          item.quantity = action.payload.quantity;
          item.isQuantityValid =
            Number(action.payload.quantity) > 0 && item.isSelected
              ? true
              : false;
          item.isQuantityEdited = true;
        }
      });
    },

    setReasonState: (state, action: PayloadAction<RMAUpdateField>) => {
      state.searchResult?.data?.items.forEach((item: any) => {
        if (item.itemNumber === action.payload.itemNumber) {
          item.reason = action.payload.reason;
        }
      });
    },

    setIsSelectedState: (state, action: PayloadAction<RMAUpdateField>) => {
      state.searchResult?.data?.items.forEach((item: any) => {
        if (action.payload.isSelected.includes(item.itemNumber)) {
          item.isSelected = true;
          item.isQuantityValid =
            Number(item.quantity) > 0 && item.isQuantityEdited ? true : false;
        } else {
          item.isSelected = false;
          item.isQuantityValid = false;
        }
      });
    },
    setRMASearchType: (state, action: PayloadAction<SearchType>) => {
      state.rmaSearchType = action.payload;
    },

    setRMAScreenStage: (state, action: PayloadAction<ScreenStage>) => {
      state.rmaScreenStage = action.payload;
    },
    resetRMAEntry: (state) => {
      //state.searchResult = AsyncStateObjectInitialStateFactory();
      return initialState;
    },
    setSearchRmaResult: (
      state,
      action: PayloadAction<Partial<RmaEntrySearchState["searchResult"]>>
    ) => {
      state.searchResult = Object.assign(state.searchResult, action.payload);
    },
    setRMAItemValidationError: (state, action: PayloadAction<string>) => {
      state.searchResult?.data?.items.forEach((item: any) => {
        if (item.itemNumber === action.payload) {
          item.validationResults = [
            {
              level: "Error",
              reason: "API failed",
              message:
                "Oops! Something went wrong while validating the invoice item.",
            },
          ];
          item.isSelected = false;
        }
      });
    },
  },

  extraReducers(builder) {
    // THUNK getSystemBrands

    builder.addCase(RMAItemValidation.pending, (state) => {
      state.searchResult.status = AsyncStatus.LOADING;
    });

    builder.addCase(RMAItemValidation.fulfilled, (state, { payload }) => {
      state.searchResult.status = AsyncStatus.SUCCEEDED;
      console.log("action.payload", payload);
      if (payload) {
        let result: RMAInvoiceValidateResponse = payload;
        state.searchResult?.data?.items.forEach((item: any) => {
          let filteredItemValidationArray = result.items
            ?.filter((x: any) => x.invoiceLineNumber === item.itemNumber)
            .map((ele: any) => {
              return ele.validationResults;
            });
          console.log(
            "filteredItemValidationArray",
            filteredItemValidationArray
          );
          item.validationResults = filteredItemValidationArray[0];
        });
      }
    });

    builder.addCase(RMAItemValidation.rejected, (state, { error }) => {
      state.searchResult.status = AsyncStatus.FAILED;
      state.searchResult.error =
        error.message ||
        "An unknown error occurred validating the Invoice Items.";
      state.itemsValidtionFailed = true;
    });

    builder.addCase(searchRMAEntry.pending, (state) => {
      state.searchResult.status = AsyncStatus.LOADING;

      state.searchResult.error = undefined;

      state.searchResult.data = undefined;
    });

    builder.addCase(searchRMAEntry.fulfilled, (state, { payload }) => {
      state.searchResult.status = AsyncStatus.SUCCEEDED;
      state.rmaScreenStage = ScreenStage.requestForm;
      const mappedResponseData = mapSalesApiResponseToRMA(payload);
      if (state.formReviewDetails) {
        state.formReviewDetails.purchaseOrderNumber =
          mappedResponseData?.purchaseOrderNumber || "";
      }
      state.searchResult.data = mappedResponseData;
    });

    builder.addCase(searchRMAEntry.rejected, (state, { error }) => {
      state.searchResult.status = AsyncStatus.FAILED;

      state.searchResult.error =
        error.message || "An unknown error occurred while searching for RMA.";

      console.error("searchRma", error);
    });
  },
});
