import * as yup from "yup";
import {
  FreightTermsEnum,
  Item,
  OrderShipmentType,
} from "models/HubbellConnectOrder";
import { endOfToday, endOfYesterday, isDate, parse, sub, add } from "date-fns";
import { isReplacementParts } from "../../components/CartActionEventListener/OrderEventModal";
import {
  maximumItemPurchaseOrderLineNumber,
  maximumItemQuantity,
} from "./constants";
import { ValidationError } from "yup";
import { draftItemHasMessages } from "../helpers";
import { selectCartItems } from "../selectors";

const today = endOfYesterday();
const past7days = sub(today, { days: 7 });
const future30days = add(endOfToday(), { days: 30 });

export const parseDate = (value: any, originalValue?: any) => {
  if (value) {
    if (isDate(value)) {
      return value as Date;
    } else {
      return parse(value as string, "MM/dd/yyyy", new Date());
    }
  } else {
    return null;
  }
};

export const OrderItemSchema = yup.object().shape({
  purchaseOrderLineNumber: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required")
    .min(1, "Must be greater than zero.")
    .max(maximumItemPurchaseOrderLineNumber, "Max value exceeded."),
  quantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required")
    .min(1, "Must be greater than zero")
    .max(maximumItemQuantity, "Max value exceeded"),
  materialNumber: yup.string().required(),
  lineNumber: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required")
    .min(1, "Invalid line number"),
  unitPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required")
    .min(0.01, "Invalid price value"),
  plantNumber: yup.string().required("Required"),
  plantDescription: yup.string().required("Required"),
  plantQuoteNumber: yup.string().max(10, "Max length exceeded"),
});

export const OrderItemSchemaStrict = OrderItemSchema.shape({
  purchaseOrderLineNumber: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required")
    .min(1, "Must be greater than zero.")
    .max(maximumItemPurchaseOrderLineNumber, "Max value exceeded.")
    .test(
      "duplicatePOLine",
      "Must be unique",
      checkForPOLineNumberDuplicateValues
    ),
});

export function validateOrderItem(item: Item, path?: string, strict?: boolean) {
  try {
    if (path) {
      OrderItemSchemaStrict.validateSyncAt(path, item);
    } else {
      strict
        ? OrderItemSchemaStrict.validateSync(item)
        : OrderItemSchema.validateSync(item);
    }
    return true;
  } catch (error: unknown) {
    return error as ValidationError;
  }
}

export function validateOrderItems(items: Item[]) {
  let checks = new Array<boolean | ValidationError>();
  items.forEach((item: Item, i) => {
    checks[i] = validateOrderItem(item, undefined, true);

    if (checks[i] === true) {
      checks[i] = !draftItemHasMessages(item);
    }
  });
  const isValid = checks.filter((i) => i !== true).length === 0;
  return isValid;
}

export function checkForPOLineNumberDuplicateValues(value: any, context: any) {
  const items = selectCartItems();
  var hasDuplicates =
    items?.find(function (item) {
      return (
        value?.toString() === item.purchaseOrderLineNumber &&
        context?.parent?.orderItemId !== item.orderItemId
      );
    }) !== undefined;
  return !hasDuplicates;
}

export const OrderDetailSchema = yup.object().shape({
  purchaseOrderNumber: yup
    .string()
    .required("Required")
    .when("payWithCreditCard", {
      is: (payWithCreditCard: boolean) => payWithCreditCard === true,
      then: (schema) => schema.max(17, "Max 17 characters"),
      otherwise: (schema) => schema.max(20, "Max 20 characters"),
    }),

  payWithCreditCard: yup.boolean().oneOf([true, false], "Invalid input"),

  rush: yup.boolean().oneOf([true, false], "Invalid input"),

  orderType: yup
    .string()
    .required("Required")
    .when("brandSupportsExports", ([brandSupportsExports], schema) => {
      return brandSupportsExports === true
        ? schema
        : schema.test(
            "brand-support",
            "Brand does not support exports. Please remove items from your cart, or change the shipping address.",
            (value, context) => value !== OrderShipmentType.Export
          );
    }),

  freightTermsName: yup.string().required("Required"),

  freightTermsValue: yup.string().required("Required"),

  carrierPreference: yup.string().when("freightTermsName", {
    is: (freightTermsName: FreightTermsEnum) =>
      [FreightTermsEnum.CollectYOB, FreightTermsEnum.ThirdParty].includes(
        freightTermsName
      ),
    then: (schema) => schema.required("Required"),
  }),

  carrierPreferenceName: yup.string().when("freightTermsName", {
    is: (freightTermsName: FreightTermsEnum) =>
      [FreightTermsEnum.CollectYOB, FreightTermsEnum.ThirdParty].includes(
        freightTermsName
      ),
    then: (schema) => schema.required("Required"),
  }),

  freightAccountNumber: yup.string().when("freightTermsName", {
    is: (freightTermsName: FreightTermsEnum) =>
      [FreightTermsEnum.CollectYOB, FreightTermsEnum.ThirdParty].includes(
        freightTermsName
      ),
    then: (schema) => schema.required("Required").max(20, "Max 20 characters"),
    otherwise: (schema) => schema.notRequired(),
  }),

  destinationCountry: yup.string().when("orderType", {
    is: (orderType: OrderShipmentType) =>
      [OrderShipmentType.Export].includes(orderType),
    then: (schema) => schema.required("Required"),
  }),

  freightThirdPartyAddress_Name: yup.string().when("freightTermsName", {
    is: (freightTermsName: FreightTermsEnum) =>
      [FreightTermsEnum.ThirdParty].includes(freightTermsName),
    then: (schema) => schema.required("Required").max(35, "Max 35 characters"),
  }),

  freightThirdPartyAddress_Address: yup.string().when("freightTermsName", {
    is: (freightTermsName: FreightTermsEnum) =>
      [FreightTermsEnum.ThirdParty].includes(freightTermsName),
    then: (schema) => schema.required("Required").max(35, "Max 35 characters"),
  }),

  freightThirdPartyAddress_City: yup.string().when("freightTermsName", {
    is: (freightTermsName: FreightTermsEnum) =>
      [FreightTermsEnum.ThirdParty].includes(freightTermsName),
    then: (schema) => schema.required("Required").max(35, "Max 35 characters"),
  }),

  freightThirdPartyAddress_State: yup.string().when("freightTermsName", {
    is: (freightTermsName: FreightTermsEnum) =>
      [FreightTermsEnum.ThirdParty].includes(freightTermsName),
    then: (schema) => schema.required("Required"),
  }),

  freightThirdPartyAddress_Country: yup.string().when("freightTermsName", {
    is: (freightTermsName: FreightTermsEnum) =>
      [FreightTermsEnum.ThirdParty].includes(freightTermsName),
    then: (schema) =>
      schema
        .required("Required")
        .max(2, "Max 2 characters")
        .matches(/^[a-zA-Z]*$/, {
          message: "Alphabetic characters only",
          excludeEmptyString: true,
        }),
  }),

  freightThirdPartyAddress_PostalCode: yup.string().when("freightTermsName", {
    is: (freightTermsName: FreightTermsEnum) =>
      [FreightTermsEnum.ThirdParty].includes(freightTermsName),
    then: (schema) =>
      schema
        .required("Required")
        .min(3, "Min 3 characters")
        .max(10, "Max 10 characters")
        .matches(/^[a-zA-Z0-9 -]*$/, {
          message: "Alpha-numeric. Spaces and hyphens allowed.",
          excludeEmptyString: true,
        }),
  }),

  notes: yup.string().max(132, "Max 132 characters"),

  carrierInstructions: yup.string().max(132, "Max 132 characters"),

  specialInstructions: yup.string().max(132, "Max 132 characters"),

  markings: yup.string().max(105, "Max 105 characters"),

  internalOrderNotes: yup.string().max(105, "Max 105 characters"),

  agentNotes: yup.string().max(105, "Max 105 characters"),

  purchaseOrderDate: yup
    .date()
    .transform(parseDate)
    .typeError("Invalid date")
    .min(past7days, "Max 7 days into the past")
    .max(future30days, "Max 30 days into the future"),

  requestedShipDate: yup
    .date()
    .transform(parseDate)
    .typeError("Invalid date")
    .min(today, "Date can not be in the past"),

  poAttachmentUrl: yup.string().when("orderDraftType", {
    is: (orderDraftType: any) => !isReplacementParts(orderDraftType),
    then: (schema) => schema.required("Required"),
  }),
});

export const OrderShippingDetailSchema = yup.object().shape({
  name1: yup.string().max(35, "Max 35 characters").required("Required"),
  address1: yup.string().max(35, "Max 35 characters").required("Required"),
  address2: yup.string().max(35, "Max 35 characters").nullable(),
  city: yup.string().max(35, "Max 35 characters").required("Required"),
  region: yup.string().required("Required"),
  postal: yup
    .string()
    .min(3, "Min 3 characters")
    .max(10, "Max 10 characters")
    .matches(/^[a-zA-Z0-9\s-]+$/, "Alpha-numeric. Spaces and hyphens allowed.")
    .required("Required"),
  country: yup.string().length(2).default("US"),
  isUserSupplied: yup.boolean(),
});

export const isFieldRequired = (fieldName: string, values: Object): boolean => {
  const field = OrderDetailSchema.describe({
    value: values,
  }).fields[fieldName] as any;
  const r = field?.tests.some((item: any) => item.name === "required") ?? false;
  return r;
};
