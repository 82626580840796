import HubbellConnectOrder, {
  Address,
  Detail,
  Item,
  OrderAddressTypeName,
} from "models/HubbellConnectOrder";
import { getState, getUserProfileState } from "./useCart";
import { UserAccount as ProfileAddress } from "models/UserAccount";

export const selectCartOrder = (): HubbellConnectOrder | undefined =>
  getState().orderDraft.data;

export const selectCartSoldToAddress = (): Address | undefined =>
  getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressType?.name === OrderAddressTypeName.SoldTo
  );

export const selectCartShippingAddress = (): Address | undefined =>
  getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressType?.name === OrderAddressTypeName.ShipTo
  );
export const selectCartBillingAddress = (): Address | undefined =>
  getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressType?.name === OrderAddressTypeName.BillTo
  );

export const selectCart3rdPartyFreightAddress = (
  addressId: string
): Address | undefined =>
  getState().orderDraft.data?.orderAddresses.find(
    (a: Address) =>
      a.orderAddressType?.name === OrderAddressTypeName.ThirdPartyFreight &&
      a.orderAddressId === addressId
  );

export const selectCartAddressById = (addressId: string): Address | undefined =>
  getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressId === addressId
  );

export const selectCartAddressValidation = () => getState().addressValidation;

export const selectCartOrderDetail = (): Detail[] | undefined =>
  getState().orderDraft.data?.details;

export const selectCartCheckoutValidity = (): string =>
  getState().checkoutValidity.orderDetails;

export const selectCartItems = (): Item[] | undefined =>
  getState().orderDraft.data?.items;

export const selectUserProfileSoldToAddress = (): ProfileAddress | undefined =>
  getUserProfileState().currentAccount;

export const selectOrderId = (): string | undefined =>
  getState().orderDraft.data?.orderId;

export const selectOrderAddressId = (
  addressName: string | undefined
): string | undefined => {
  let addressId = getState().orderDraft.data?.orderAddresses.find(
    (a: Address) => a.orderAddressType?.name === addressName
  );
  return addressId?.orderAddressId;
};

export const selectOrderDetailAddressId = (
  orderDetailId: string | undefined
) => {
  return getState().orderDraft.data?.details.find(
    (d) => d.orderDetailId === orderDetailId
  )?.freightThirdPartyAddress?.orderAddressId;
};

export const selectOrderDetailId = (): string | undefined =>
  getState().orderDraft.data?.details[0]?.orderDetailId;

export const selectCartItemData = () => getState().itemData;

export const selectCartValidation = () => getState().cartValidation;

export const selectOrderType = () => getState().orderDraft.data?.orderType.name;
