import {
  ReactElement,
  Suspense,
  // useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  matchPath,
  Navigate,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import PageNotFound from "./PageNotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIconDefinition } from "../util/iconHelper";
import lazyWithRetry from "../util/lazyWithRetry";
import {
  MappedSystemState,
  mapSystemStateToProps,
  SystemState,
} from "../store/systemStore";
import { useSelector } from "react-redux";
import { AppState } from "../store/app";
import { useIsAuthenticated } from "@azure/msal-react";
import ScrollToTop from "./ScrollToTop";
import ErrorPage from "./ErrorPage";

export interface RouteDefinition {
  name: string;
  admin?: boolean;
  iconName?: string;
  description?: string;
  header?: boolean; // added to denote whether the module is for header (true means it should show in header)
  body?: boolean; // added to denote whether the module is for body (true means it should show in body as card)
  path: string;
  title: string;
  render: Function;
  public?: boolean;
  icon?: ReactElement;
}

const module_routes: Array<RouteDefinition> = [
  {
    path: "/admin/*",
    render: lazyWithRetry(() => import("../modules/Admin")),
    title: "Admin Dashboard",
    public: false,
    name: "admin",
    admin: true,
    header: true,
    body: true,
  },
  {
    path: "/price-availability",
    render: lazyWithRetry(() => import("../modules/PriceAndAvailability")),
    title: "Price and Availability",
    public: false,
    name: "price-availability",
    header: true,
    body: true,
  },
  {
    path: "/order-entry/*",
    render: lazyWithRetry(() => import("../features/OrderEntry")),
    title: "Order Entry",
    public: false,
    name: "order-entry",
    header: true,
    body: false,
  },
  {
    path: "/order-search",
    render: lazyWithRetry(() => import("../features/OrderSearch")),
    title: "Order Status/Search",
    public: false,
    name: "order-search",
    header: true,
    body: true,
  },
  {
    path: "/quotes",
    render: lazyWithRetry(() => import("../features/QuoteSearch")),
    title: "Quotes",
    public: false,
    name: "quotes",
    header: true,
    body: true,
  },

  {
    path: "/part-cross-reference",
    render: lazyWithRetry(() => import("../modules/CrossReferenceSearch")),
    title: "Part Cross Reference",
    public: true,
    name: "part-cross-reference",
    header: true,
    body: true,
  },
  {
    path: "/price-files",
    render: lazyWithRetry(() => import("../features/PriceFiles")),
    title: "Price Files",
    public: false,
    name: "price-files",
    header: true,
    body: true,
  },
  {
    path: "/rma-search",
    render: lazyWithRetry(() => import("../features/RMARequests")),
    title: "RMA Requests",
    public: false,
    name: "rma-search",
    header: true,
    body: true,
  },
  {
    path: "/invoice-search",
    render: lazyWithRetry(() => import("../features/InvoiceSearch")),
    title: "Invoice Search",
    public: false,
    name: "invoice-search",
    header: true,
    body: true,
  },
  {
    path: "/rma-entry",
    render: lazyWithRetry(() => import("../features/RMAEntry")),
    title: "RMA Entry",
    public: false,
    name: "rma-entry",
    header: true,
    body: true,
  },
  {
    path: "/replacement-parts",
    render: lazyWithRetry(() => import("../features/ReplacementParts")),
    title: "Replacement Parts",
    public: false,
    name: "replacement-parts",
    header: true,
    body: true,
  },
];

const other_routes: Array<RouteDefinition> = [
  {
    name: "home",
    path: "/",
    render: lazyWithRetry(() => import("../modules/Home")),
    title: "Home",
    public: false,
  },
  {
    path: "/home",
    render: () => <Navigate to={"/"} replace />,
    name: "_home",
    title: "Home",
  },
  {
    name: "user-profile",
    path: "/user/*",
    render: lazyWithRetry(() => import("../modules/User")),
    title: "User Profile",
    public: false,
  },
  {
    name: "login",
    path: "/login",
    title: "Log In",
    render: lazyWithRetry(() => import("./LogIn")),
    public: true,
  },
  {
    name: "no-accounts",
    path: "/no-accounts",
    title: "No Accounts Found",
    render: lazyWithRetry(() => import("./NoAccountsLandingPage")),
    public: false,
  },
  {
    name: "inactive-account",
    path: "/inactive-account",
    title: "Account Inactive",
    render: lazyWithRetry(() => import("./InactiveAccountLandingPage")),
    public: false,
  },
  {
    name: "support",
    path: "/support",
    render: lazyWithRetry(() => import("../modules/Support")),
    title: "Support",
    public: true,
  },
  {
    path: "/part-cross-reference",
    render: lazyWithRetry(() => import("../modules/CrossReferenceSearch")),
    title: "Part Cross Reference",
    public: true,
    name: "part-cross-reference",
  },
  {
    path: "/order-detail",
    render: lazyWithRetry(() => import("../pages/DetailPage")),
    title: "Order Detail",
    public: false,
    name: "order-detail",
  },
  {
    path: "/quote-detail",
    render: lazyWithRetry(() => import("../pages/DetailPage")),
    title: "Quote Detail",
    public: false,
    name: "quote-detail",
  },
];

if (process.env.NODE_ENV === "development") {
  other_routes.push({
    name: "dev-sandbox",
    path: "/dev",
    title: "dev",
    render: lazyWithRetry(() => import("../modules/Dev")),
    public: true,
  });
}

export function useRoutes(): {
  moduleRoutes: Array<RouteDefinition>;
  allRoutes: Array<RouteDefinition>;
  currentRoute: RouteDefinition | undefined;
} {
  const system: MappedSystemState = mapSystemStateToProps(
    useSelector<AppState>((state) => state.system) as SystemState
  );

  const [currentRoute, setCurrentRoute] = useState<RouteDefinition | undefined>(
    undefined
  );

  const [userModuleRoutes, setUserModuleRoutes] = useState<
    Array<RouteDefinition> | []
  >([]);

  const [allRoutes, setAllRoutes] = useState<Array<RouteDefinition> | []>([]);
  const location = useLocation();

  useLayoutEffect(() => {
    if (
      system.isReady === true &&
      userModuleRoutes.length === 0 &&
      allRoutes.length === 0
    ) {
      let module_routes_updated: Array<RouteDefinition> = [];

      module_routes_updated = module_routes.map((item: any) => {
        let module = system.findModule(item.name);
        if (module !== undefined) {
          item.title = module.title;
          item.public = module.isPublic;
          item.admin = module.isAdmin;
          //item.path = module.redirectPath;
          if (module.icon !== undefined && module.icon.shortName !== "") {
            item.iconName = module.icon.shortName;
          }
        }
        if (item.iconName !== undefined && item.iconName !== "") {
          let iconDefn = getIconDefinition(item.iconName);
          item.icon = <FontAwesomeIcon icon={iconDefn} fixedWidth />;
        }
        return item;
      });

      let authorizedModuleRoutes = module_routes_updated.filter(
        (m: RouteDefinition) => system.userModuleAuthorizations(m.name)
      );
      setUserModuleRoutes(authorizedModuleRoutes);

      let _allroutes: Array<RouteDefinition> = [];
      const allAppRoutes = _allroutes
        .concat(authorizedModuleRoutes)
        .concat(other_routes);
      setAllRoutes(allAppRoutes);
    }
  }, [userModuleRoutes, system, allRoutes, system.isReady]);

  useLayoutEffect(() => {
    let match = allRoutes.find((m: RouteDefinition) => {
      const match = matchPath(m.path, location.pathname);
      return null !== match;
    });
    setCurrentRoute(match as RouteDefinition);
  }, [location, allRoutes]);

  return {
    moduleRoutes: userModuleRoutes,
    allRoutes: allRoutes.length ? allRoutes : other_routes,
    currentRoute: currentRoute,
  };
}

export function AppRoutes() {
  const isAuthenticated = useIsAuthenticated();
  const system = useSelector<AppState, MappedSystemState>((state) =>
    mapSystemStateToProps(state.system)
  );
  const routes = useRoutes();
  const _routes = useMemo(
    () =>
      routes.allRoutes.map((r: RouteDefinition) => (
        <Route
          path={r.path}
          key={r.path}
          element={
            <AuthRoute
              routeDef={r}
              isActive={system.userSystemRecord?.isActive || false}
              isAuthenticated={isAuthenticated}
              hasAccounts={system.userSystemRecord?.accountsAssigned || false}
            />
          }
        />
      )),
    [system, routes, isAuthenticated]
  );
  return system.isReady ? (
    <Suspense fallback={<Spinner animation="grow" />}>
      <Routes>
        {_routes}
        <Route
          path="*"
          element={isAuthenticated ? <PageNotFound /> : <Navigate to="/" />}
        />
      </Routes>
    </Suspense>
  ) : system.hasError ? (
    <ErrorPage />
  ) : (
    <div className="text-center container my-5 routes">
      Loading application settings...
    </div>
  );
}

export const AuthRoute: React.FC<{
  routeDef: RouteDefinition;
  isAuthenticated: boolean;
  isActive: boolean;
  hasAccounts: boolean;
}> = ({ routeDef, isAuthenticated, isActive, hasAccounts }) => {
  let requestedUrl = window.location.href;
  let route: any = null;
  if (routeDef?.public === false) {
    if (!isAuthenticated) {
      route = (
        <Navigate
          to={{
            pathname: "/login",
          }}
          state={{ from: requestedUrl }}
        />
      );
    } else {
      if (!isActive && routeDef.path !== "/inactive-account") {
        route = (
          <Navigate
            to="/inactive-account"
            state={{ from: requestedUrl }}
            replace={true}
          />
        );
      }
      // refactor as non-route component
      if (
        !hasAccounts &&
        isActive &&
        routeDef.path !== "/user/*" &&
        routeDef.path !== "/no-accounts"
      ) {
        route = (
          <Navigate
            to="/no-accounts"
            state={{ from: requestedUrl }}
            replace={true}
          />
        );
      }
    }
  }
  if (!route) {
    route = (
      <>
        <ScrollToTop />
        <routeDef.render title={routeDef.title} />
      </>
    );
  }
  return route;
};
