import { CartOrderEntryOrderValidation } from "models/OrderEntrySAPValidation";
import HubbellConnectOrder, { Item } from "models/HubbellConnectOrder";
import SapRfcIntegrationApi from "services/SapRfcIntegrationApi";
import { AsyncStateObject } from "store/AsyncStateObject";
import { DataQuality } from "services/DataQuality";

export enum FormStatus {
  VALID = "true",
  INVALID = "false",
}

export enum HubbellOrderItemUpdateType {
  price = "price",
  inventory = "inventory",
  priceInventory = "price-inventory",
}

export interface AddressUpdateAsyncStateObject extends AsyncStateObject<any> {
  addressId: string;
}
export interface AddressValidateAsyncStateObject
  extends AsyncStateObject<DataQuality.Output.ValidateAddress> {
  addressId: string;
  orderDetailId?: string;
}

export interface ItemPriceUpdateAsyncStateObject
  extends AsyncStateObject<SapRfcIntegrationApi.Materials.Output.PriceAndAvailability> {
  updateType: HubbellOrderItemUpdateType;
  orderItemId: string;
}
export interface ItemUpdateAsyncStateObject extends AsyncStateObject<Item> {
  time: string;
}
interface CartState {
  orderDraft: AsyncStateObject<HubbellConnectOrder>;
  checkoutValidity: {
    items: FormStatus;
    shippingDetails: FormStatus;
    orderDetails: FormStatus;
  };
  loading: boolean;
  itemData: Array<ItemPriceUpdateAsyncStateObject>;
  itemUpdateQueue: Array<ItemUpdateAsyncStateObject>;
  cartValidation: AsyncStateObject<CartOrderEntryOrderValidation[]>;
  addressValidation: Array<AddressValidateAsyncStateObject>;
  addressUpdate: Array<AddressUpdateAsyncStateObject>;
}
export default CartState;
