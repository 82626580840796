import { FC, PropsWithChildren } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faLinkedin,
  faYoutubeSquare,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import connectLogo from "../../assets/images/HubbellConnectLogo.svg";
import hubbellLogo from "../../assets/images/logo-hubbell.png";
import canadaLogo from "../../assets/images/logo-hubbellcanada.png";
import Page from "../PageLayout";
import "./styles.scss";
import EnvironmentIndicator from "../EnvironmentIndicator";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { CountryCode } from "store/system/constants";

const UnauthenticatedPage: FC<PropsWithChildren> = (props): JSX.Element => {
  const country = CountryCode;
  const year = new Date().getFullYear();
  return (
    <UnauthenticatedTemplate>
      <Page className="unauthenticatedLandingPage">
        <Page.Header>
          <span className="corpLogo d-none d-md-block">
            <img
              src={country === "CA" ? canadaLogo : hubbellLogo}
              alt="Hubbell Connect"
            />
          </span>
          <span className="appLogo">
            <Link to="/">
              <img src={connectLogo} alt="Hubbell Connect" />
            </Link>
          </span>
          <div className="headerNav">
            <div className="justify-self-start">
              {country === "CA" ? (
                <a
                  href="https://www.hubbell.ca"
                  className="btn btn-clear fw-normal d-none d-sm-inline-block"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Hubbell.ca
                </a>
              ) : (
                <a
                  href="https://www.hubbell.com"
                  className="btn btn-clear fw-normal d-none d-sm-inline-block"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Hubbell.com
                </a>
              )}
              <Link to="/support" className="btn btn-clear fw-normal">
                Support
              </Link>
            </div>
          </div>
        </Page.Header>
        <Page.Body>{props.children}</Page.Body>
        <Page.Footer>
          <Container fluid="md">
            <Row>
              <Col sm={3}>
                <h6 className="section-title hr-after">CUSTOMER SUPPORT</h6>
                <a
                  href="https://www.hubbell.com/hubbell/en/contactUs"
                  title="Contact Us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </Col>
              <Col sm={3}>
                <h6 className="section-title hr-after">OUR COMPANY</h6>
                <a
                  href="https://www.hubbell.com/hubbell/en/about-hubbell"
                  title="About Us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About Us
                </a>
                <a
                  href="https://www.hubbell.com/hubbell/en/brands"
                  title="Our Brands"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Our Brands
                </a>
                <a
                  href="http://investor.hubbell.com/?tab=press"
                  title="Press Releases"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press Releases
                </a>
                <a
                  href="http://careers.hubbell.com/"
                  title="Careers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </a>
                <a
                  href="http://investor.hubbell.com/"
                  title="Investors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Investors
                </a>
              </Col>

              <Col sm={{ span: "3", offset: "3" }}>
                <h6 className="section-title hr-after">GET IN TOUCH</h6>
                <p>
                  <span>Hubbell Incorporated</span>
                  <br />
                  <span>40 Waterview Drive</span>
                  <br />
                  <span>Shelton</span>, <span>Connecticut</span>
                  <br />
                  <span>USA</span> <span>06484-1000</span>
                </p>
                <div className="social-links">
                  <a
                    className="btn btn-link"
                    href="https://twitter.com/HubbellCorp?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                    <span className="sr-only">Twitter</span>
                  </a>
                  <a
                    className="btn btn-link"
                    href="https://www.youtube.com/user/HUBBELLINCORPORATED"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutubeSquare} />
                    <span className="sr-only">youTube</span>
                  </a>
                  <a
                    className="btn btn-link"
                    href="https://www.linkedin.com/company/hubbell?trk=hb_tab_compy_id_122771"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faLinkedin} />
                    <span className="sr-only">linkdIn</span>
                  </a>
                </div>
              </Col>
            </Row>

            <div id="copyright">
              <a
                href="https://www.hubbell.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="corpLogo"
                  title="Hubbell"
                  alt="Hubbell"
                  src={hubbellLogo}
                />
              </a>

              <div>&copy; {year} Hubbell Incorporated. All Rights Reserved</div>

              <div>
                <span className="separator"></span>
                <a
                  href="https://www.hubbell.com/hubbell/en/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                <span className="separator"></span>
                <a
                  href="https://www.hubbell.com/hubbell/en/web-terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
              </div>
            </div>
            <EnvironmentIndicator />
          </Container>
        </Page.Footer>
      </Page>
    </UnauthenticatedTemplate>
  );
};
export default UnauthenticatedPage;
